/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */

'use strict';

/**
 * Queue for interaction events.
 */
var EventsQueue = {

    list: [],
    timer: null,

    add: function addTealium(eventData) {
        this.list.unshift(eventData); // Add to beginning of array.

        if (!this.timer) {
            this.runDelayed();
        }
    },

    runDelayed: function runTealiumDelayed() {
        if (this.list.length > 0) {
            this.timer = window.setTimeout(
                this.run.bind(this, this.list.pop()), // Remove last item.
                3 // Run call with a tiny delay to allow the browser to repaint.
            );
        }
    },

    run: function runTealium(eventData) {
        this.timer = null; // Reset timer property.
        var utag = window.utag;

        if (typeof (utag) !== 'undefined') {
            utag.page_path = eventData.pathname;
            if (eventData.eventType === 'view') {
                utag.view(eventData.data, eventData.callbackFn);
            } else if (typeof (eventData.data.event_type) !== 'undefined') {
                utag.link(eventData.data, eventData.callbackFn);
            }
        }

        if (typeof (window.utag_data) !== 'undefined') { /* eslint-disable-line camelcase */
            window.utag_data = $.extend({}, utag_data, eventData.data); /* eslint-disable-line camelcase */
        }
        $(document).trigger('tealiumUtagIsReady');
        this.runDelayed();
    }
};

var tealium = {
    eventCount: 0,
    checkElement: function (e) {
        var obj = {
            href: '',
            label: ''
        };
        if (e) {
            if (e.target.tagName === 'A') {
                obj.href = e.target.href;
                obj.label = e.target.text.trim();
            } else {
                obj.href = $(e.target).parent().attr('href');
                obj.label = $(e.target).parent().text().trim();
            }
        }
        return obj;
    },
    triggerEvent: function (data, eventType, callbackFn) {
        if (typeof (data) === 'undefined') {
            return;
        }
        if (typeof (eventType) === 'undefined') {
            eventType = 'link';
        }

        if (!callbackFn && typeof (callbackFn) !== 'function') {
            callbackFn = false;
        }

        EventsQueue.add({
            data: data,
            eventType: eventType,
            pathname: window.location.pathname,
            callbackFn: callbackFn
        });
    },
    getDevice: function () {
        var navUserAgent = navigator.userAgent;
        var userAgent = '';
        if (navUserAgent.match(/Phone/i) || navUserAgent.match(/DROID/i) || navUserAgent.match(/Android/i) || navUserAgent.match(/webOS/i) || navUserAgent.match(/iPhone/i) || navUserAgent.match(/iPod/i) || navUserAgent.match(/BlackBerry/) || navUserAgent.match(/Windows Phone/i) || navUserAgent.match(/ZuneWP7/i) || navUserAgent.match(/IEMobile/i)) {
            userAgent = 'Mobile';
        } else if (navUserAgent.match(/Tablet/i) || navUserAgent.match(/iPad/i) || navUserAgent.match(/Kindle/i) || navUserAgent.match(/Playbook/i) || navUserAgent.match(/Nexus/i) || navUserAgent.match(/Xoom/i) || navUserAgent.match(/SM-N900T/i) || navUserAgent.match(/GT-N7100/i) || navUserAgent.match(/SAMSUNG-SGH-I717/i) || navUserAgent.match(/SM-T330NU/i)) {
            userAgent = 'Tablet';
        } else {
            userAgent = 'Desktop';
        }
        return userAgent;
    },
    getProductDetails: function (pid) {
        var preUrl = $('.wishlist-api').data('wishlisturl');
        if (!preUrl || !pid) {
            // Return a never resolving promise to avoid potential errors in caller.
            return new Promise(function () {});
        }
        var EVENT = 'event';
        var url = preUrl + '?pid=' + pid;
        return new Promise(function (resolve, reject) {
            var performRequest = function () {
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        var productInfo = data.productInfo;
                        var productArray = {
                            product_name: [],
                            product_brand: [],
                            product_unit_price: [],
                            product_currency: [],
                            product_sku: [],
                            product_style_number: [],
                            adobe_product_id: [],
                            product_id: [],
                            product_variant_id: [],
                            product_master: [],
                            product_category: [],
                            product_subcategory: [],
                            product_quantity: [],
                            product_availability: [],
                            product_vat: [],
                            product_gender: [],
                            product_color: [],
                            product_size: [],
                            product_width: [],
                            product_gbu: [],
                            product_line: [],
                            product_type: [],
                            product_silhouette: [],
                            original_product_unit_price: [],
                            category_path: []
                        };

                        if (productInfo) {
                            productArray.product_brand.push(productInfo.product_brand);
                            productArray.product_name.push(productInfo.product_name);
                            productArray.product_unit_price.push(productInfo.product_unit_price);
                            productArray.product_currency.push(productInfo.product_currency);
                            productArray.product_sku.push(productInfo.product_sku);
                            productArray.product_style_number.push(productInfo.product_style_number);
                            productArray.product_id.push(productInfo.product_id);
                            productArray.adobe_product_id.push(productInfo.adobe_product_id);
                            if (productInfo.product_variant_id !== '') {
                                productArray.product_variant_id.push(productInfo.product_variant_id);
                            }
                            productArray.product_master.push(productInfo.product_master);
                            productArray.product_category.push(productInfo.product_category);
                            productArray.product_subcategory.push(productInfo.product_subcategory);
                            productArray.product_quantity.push(Math.round(productInfo.product_quantity));
                            productArray.product_availability.push(productInfo.product_availability);
                            productArray.product_vat.push(productInfo.product_vat);
                            productArray.product_gender.push(productInfo.product_gender);
                            productArray.product_color.push(productInfo.product_color);
                            productArray.product_size.push(productInfo.product_size);
                            productArray.product_width.push(productInfo.product_width);
                            productArray.product_gbu.push(productInfo.product_gbu);
                            productArray.product_line.push(productInfo.product_line);
                            productArray.product_type.push(productInfo.product_type);
                            productArray.product_silhouette.push(productInfo.product_silhouette);
                            productArray.original_product_unit_price.push(productInfo.original_product_unit_price);
                            productArray.category_path.push(productInfo.category_path);
                        }
                        resolve(productArray);
                    },
                    error: function (e) {
                        reject(e);
                    }
                });
            };

            // Avoid expensive initiation of AJAX request in context click event.
            if (window[EVENT] && window[EVENT].type === 'click') {
                setTimeout(performRequest, 1);
            } else {
                performRequest();
            }
        });
    },
    tealiumHelper: function (eventType, PageName, eventMessage, gaAction, galabel, selectedpaymentmethod) {
        if (!eventType) {
            return;
        }

        var tealiumObject = {
            event_type: eventType,
            page_name: PageName,
            event_message: eventMessage,
            ga_action: gaAction,
            ga_label: galabel
        };

        if (selectedpaymentmethod) {
            tealiumObject.selected_payment_method = selectedpaymentmethod;
        }

        tealium.triggerEvent(tealiumObject);
    },
    /**
    * Get url and element when user selects any category
    * @param {Object} productImpressions - object
    */
    ajaxProductImpressionTriggerEvent: function (productImpressions) {
        if (window.utag_data && productImpressions) {
            var tlProductImpressionsObject = {
                event_type: 'product_impression',
                page_name: window.utag_data.page_name,
                ga_action: 'Product Impressions',
                product_impression_id: productImpressions.tealiumMasterIds,
                product_impression_variant_id: productImpressions.tealiumPIProdIds,
                product_impression_master_id: productImpressions.tealiumMasterIds,
                product_impression_name: productImpressions.tealiumPIProdNames,
                product_impression_brand: productImpressions.tealiumPIProdBrands,
                product_impression_category: productImpressions.tealiumPIProdCategory,
                product_impression_subcategory: productImpressions.tealiumPIProdSubCategory,
                product_impression_position: productImpressions.tealiumPIProdPosition,
                product_impression_price: productImpressions.tealiumPIProdPrices,
                category_path: window.utag_data.category_path,
                product_list: [window.utag_data.category_path]
            };
            tealium.triggerEvent(tlProductImpressionsObject);

            var tlProductImpressionsObjectvil = {
                event_type: 'view_item_list',
                item_id: productImpressions.tealiumMasterIds,
                item_id_price: productImpressions.tealiumPIProdPrices,
                item_name: productImpressions.tealiumPIProdNames,
                item_price: productImpressions.tealiumPIProdPrices,
                item_brand: productImpressions.tealiumPIProdBrands,
                item_category: productImpressions.tealiumPIProdCategory,
                item_variant: productImpressions.tealiumPIProdIds,
                item_list_id: window.utag_data.item_list_id,
                item_list_name: window.utag_data.category_path,
                index: productImpressions.tealiumPIProdPosition,
                page_name: window.utag_data.page_name,
                page_group: 'product listing',
                page_context_type: window.utag_data.page_context_type,
                page_context_title: window.utag_data.page_context_title,
                site: window.utag_data.site,
                site_section: 'product listing',
                site_type: window.utag_data.site_type,
                login_status: window.utag_data.customer_status,
                user_id: window.utag_data.user_id,
                element_type: '',
                element_text: '',
                category_path: window.utag_data.category_path,
                product_list: [window.utag_data.category_path],
                product_sku: window.tealiumProdStyles,
                product_style_number: window.tealiumProdStyles,
                product_color: window.tealiumProdColors,
                product_impression_price: productImpressions.tealiumPIProdPrices,
                product_impression_brand: productImpressions.tealiumPIProdBrands,
                product_impression_category: productImpressions.tealiumPIProdCategory,
                product_impression_color: window.tealiumProdColors,
                product_impression_gender: window.tealiumProdGenders,
                product_impression_id: productImpressions.tealiumMasterIds,
                product_impression_position: productImpressions.tealiumPIProdPosition,
                product_impression_line: window.tealiumProdLine,
                product_impression_list: [window.utag_data.category_path],
                product_impression_master_id: productImpressions.tealiumMasterIds,
                product_impression_name: productImpressions.tealiumPIProdNames,
                product_impression_size: window.tealiumProdSizes,
                product_impression_sku: window.tealiumProdStyles,
                product_impression_style_number: window.tealiumProdStyles,
                product_impression_silhouette: window.tealiumProdSilhouette,
                product_impression_variant_id: productImpressions.tealiumPIProdIds,
                product_impression_subcategory: productImpressions.tealiumPIProdSubCategory,
                product_impression_item_category: productImpressions.tealiumPIProdCategory,
                product_impression_item_category2: window.tealiumProdLine,
                product_impression_item_category3: window.tealiumProdGenders,
                product_impression_item_category4: window.tealiumProdColors,
                product_impression_item_category5: window.tealiumProdStyles
            };
            tealium.triggerEvent(tlProductImpressionsObjectvil);
        }
    }
};

module.exports = tealium;
